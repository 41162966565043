//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.p-multiselect {
    margin: 0;
    box-sizing: border-box;
    top: 10px;
    max-width: 300px;
}
.modal-form .p-inputtext.p-component,
.modal-form .p-dropdown
{
    margin-bottom: 0.5em;
}

.agent{
    padding: 15px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}
body .p-dialog {
    max-height: 95vh;
    overflow-x: auto;
}
body .p-inputswitch-slider:enabled,
body .p-inputswitch-slider:enabled:focus,
body .p-link:enabled:hover,
body .p-link:enabled:focus,
body .p-link:focus,
body .p-link:hover,
body .p-datatable-row:hover,
body .p-datatable-row:focus,
body .p-inputtext:enabled:hover,
body .p-inputtext:enabled:focus{
    outline: none!important;
    box-shadow: none!important;
}
body .p-inputtext:enabled:hover,
body .p-inputtext:enabled:focus{
    border-color:  #a6a6a6;
}
.error-field{
    input,
    .p-dropdown,
    .p-dropdown-label,
    .p-inputgroup-addon
     {
        border-color: #ff949e;
        background: #ffe6e7;
    }
    &:hover{
        border-color: #ff949e;
    }
}


.login-form{
    padding-top:20vh;
    .p-inputtext.p-component{
        width: 100%;
    }
    .p-inputgroup-addon{
        width: 36px;
    }
    & > .p-card{
        border-radius: 4px;
    }
}

.modal{
    width: 86vw;
}

.modal-form{
    .custom-fields {
        .p-inputtext.p-component, .p-dropdown{
            margin-bottom: 0em;
        }
    }    
    .custom-fields {
        &_with-defautls{
            margin-top: 0.5em;
            padding-top: 0.5em;
            border-top: 1px solid #c8c8c8;   
        }
    }
}
.p-grid.switches {
    &_top{
        margin-bottom: 1em;
        margin-top: 1em;
    }
    .p-inputtext.p-component, .p-dropdown{
        margin-bottom: 0em;
        height: 47px;
    }
    .p-col-12,
    .p-col-4,
    .p-md-6,
    .p-md-4 ,
    .p-col-6{
        display: flex;
        align-items: center;
        & > span:not(.p-calendar){
            margin-left: 0.5em;
        }
    }

}


.file-upload{
    margin-top: 2em;
    .p-fileupload .p-fileupload-content{
        display: none;
    }
    .p-fileupload .p-fileupload-buttonbar{
        background: none;
        border: none;
        padding: 0;
        margin-bottom: 1em;
    }
}
.file-link{
    padding: 0;
    color: #007ad9;
    border-bottom: 1px solid;
}


body .p-tabview .p-tabview-panels {
    background-color: transparent;
    padding: 2em 1em;
    border: 1px solid #ababab;
    color: #333333;
    border-radius: 3px;
}

body .p-breadcrumb {
    background-color: #ffffff;
    border: none;
    padding: 0.571em 1em;
    border-radius: 3px;
    margin-bottom: 1em;
}

.input-label{
    position: absolute;
    font-size: 12px;
    margin-top: -1.9em;
    background: white;
    padding: 0 6px;
    z-index: 2;
    color: #4a4d58;
}

.label-success{background: #297136; width: 8px; height: 8px; border-radius: 8px;display: inline-block; margin-right:6px;margin-top:-2px}
.label-danger{background: #ff3e3e; width: 8px; height: 8px; border-radius: 8px;display: inline-block; margin-right:6px;margin-top:-2px}

.p-calendar .p-datepicker {
    min-width: 340px;
}

.filter-th{
    .p-calendar{
        max-width: 100%;
        .p-inputtext{
            max-width: 100%;
        }
    }
}

.login-form{
    text-align: right;
}

body{background: url('https://www.umitegrioglu.com/wp-content/uploads/2019/02/Background-Fade-Tech.jpg'); background-position: left center; background-size: cover;}
.layout-wrapper{
    background: #edf0f5e8
}

body .p-inputtext {
    border: 1px solid #dadada;
}
body .p-inputswitch {
    min-width: 42px!important;
}

.p-link.p-link_profile{
    display: block;
    width: 100%;
    padding: 1em;
}

.p-datatable-thead .p-inputtext {
    max-width: 100%;
    width: 100%;
}

.p-datatable-thead .p-dropdown{
    min-width: auto;
}
.p-datatable *{
    word-break: break-all
}

body .p-datatable .p-datatable-tbody > tr > td{
    padding: .3em .3em;
}

.req:before
{   content: '*'; 
    color: #f9a6a6;
    position: absolute;
    font-size: 16px;
    user-select: none;
    z-index: 998;
    margin-top: -8px;
    margin-left: 2px;
}

.report.report_mail .p-multiselect{
    top: 0;
    width: 100%;
    max-width: 100%;
}